import React from 'react';

import './dataMethodologyPopupHealth.scss';

const DataMethodologyPopupHealth = () => {
  const handleClose = (e) => {
    window.document.body.classList.remove('dataMethodologyPopup-open');

    const scrollY = window.document.body.style.top;
    window.document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    var popup = document.querySelector('.dataMethodologyPopup');
    var backdrop = document.querySelector('.dataMethodologyPopup-backdrop');
    if (backdrop) {
      backdrop.style.display = 'none';
    }
    if (popup) {
      popup.style.display = 'none';
    }
  };

  return (
    <>
      <div className="dataMethodologyPopup">
        <div className="dataMethodologyPopup-inner">
          <div className="dataMethodologyPopup-content">
            <a href="javascript:void(0);" onClick={handleClose} className="dataMethodologyPopup-close desktop">
              <div className="dataMethodologyPopup-close-outer">
                <div className="dataMethodologyPopup-close-inner"></div>
              </div>
            </a>
            <div className="dataMethodologyPopup-title">Wie lässt sich die Umverteilung im Gesundheitswesen genau messen?</div>
            <div className="dataMethodologyPopup-text-wrapper">
              <div className="dataMethodologyPopup-text">
                <strong>DATEN</strong>
                <br />
                Die Swiss Inequality Database Health (SID Health) stützt sich auf die Daten der CSS, dem grössten Schweizer Krankenversicherer in der
                obligatorischen Krankenpflegeversicherung (OKP). Die Daten umfassen Prämien, Prämienverbilligungen, Bruttoleistungen,
                Kostenbeteiligungen, Risikoausgleichszahlungen etc. pro Individuum. Für die Analyse wurden die Daten anonymisiert und aggregiert,
                wodurch keine Rückschlüsse auf reale Personen möglich sind. Für die Berechnung der Umverteilungswirkungen der Prämienverbilligungen
                und den Kantonsanteilen an den stationären Spitalleistungen werden zusätzlich die Steuerstatistiken der Eidgenössischen
                Steuerverwaltung (ESTV) verwendet. Die Analyse beschränkt sich auf das KVG (genauer die OKP-Leistungen und deren Finanzierung), weil
                diese für die gesamte Schweizer Bevölkerung obligatorisch ist und auch eine sozialpolitische Komponente aufweist. Die SID Health ist
                für alle Jahre ab 2014 verfügbar. Dies liegt an den Daten zu den individuellen Prämienverbilligungen (IPV), die erst seit dann für
                alle Individuen verfügbar sind. In den vorherigen Jahren wurden die IPV teilweise noch direkt an die Versicherten ausbezahlt. Um ein
                vergleichbares Total bei Frage 1 zu erhalten, wurden deshalb alle Analysen erst ab dem Jahr 2014 erstellt.
                <br />
                <br />
                Aus den nachfolgenden Tabellen lassen sich einige Charakteristiken der vier Einkommensgruppen entnehmen:
                <br />
                <br />
                <table className="dataMethodologyPopup-table">
                  <thead>
                    <tr>
                      <th>Einkommensgruppe</th>
                      <th>⌀ -Einkommen</th>
                      <th>⌀ -Alter</th>
                      <th>⌀ -Bruttoleistungen</th>
                      <th>⌀ -Prämienhöhe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Unterste Einkommen (0-25%)</td>
                      <td>12‘381</td>
                      <td>47</td>
                      <td>5’806</td>
                      <td>4’622</td>
                    </tr>
                    <tr>
                      <td>Unterer Mittelstand (25-50%)</td>
                      <td>42‘252</td>
                      <td>50</td>
                      <td>4’662</td>
                      <td>4‘325</td>
                    </tr>
                    <tr>
                      <td>Oberer Mittelstand (50-75%)</td>
                      <td>70‘161</td>
                      <td>50</td>
                      <td>3’692</td>
                      <td>4‘087</td>
                    </tr>
                    <tr>
                      <td>Oberste Einkommen (75-100%)</td>
                      <td>158‘936</td>
                      <td>53</td>
                      <td>4‘112</td>
                      <td>4‘262</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <table className="dataMethodologyPopup-table">
                  <thead>
                    <tr>
                      <th>Einkommensgruppe</th>
                      <th>Anteil Rentner</th>
                      <th>Anteil Jugendliche (19-25)</th>
                      <th>Anteil Personen mit mind. einer PCG* </th>
                      <th>Anteil Spitalflag**</th>
                      <th>Anteil Personen mit freier Arztwahl</th>
                      <th>Anteil Personen mit Franchise 300.-</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Unterste Einkommen (0-25%)</td>
                      <td>20.8%</td>
                      <td>15.1%</td>
                      <td>23.9%</td>
                      <td>8.6%</td>
                      <td>27.3%</td>
                      <td>67.1%</td>
                    </tr>
                    <tr>
                      <td>Unterer Mittelstand (25-50%)</td>
                      <td>24.3%</td>
                      <td>9.6%</td>
                      <td>21.2%</td>
                      <td>6.7%</td>
                      <td>24.0%</td>
                      <td>50.7%</td>
                    </tr>
                    <tr>
                      <td>Oberer Mittelstand (50-75%)</td>
                      <td>22.9%</td>
                      <td>7.1%</td>
                      <td>16.5%</td>
                      <td>5.0%</td>
                      <td>21.4%</td>
                      <td>34.8%</td>
                    </tr>
                    <tr>
                      <td>Oberste Einkommen (75-100%)</td>
                      <td>29.2%</td>
                      <td>4.4%</td>
                      <td>16.7%</td>
                      <td>5.4%</td>
                      <td>28.9%</td>
                      <td>26.7%</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                * Pharmazeutische Kostengruppen (PCG) als Morbiditätsindikator zur Ermittlung des Anteils chronisch kranker Versicherter.
                <br />
                ** Kategorisierung der Spitalaufenthalte gemäss Definition im Risikoausgleich zur Ermittlung des Anteils Versicherter mit Folgekosten.
                <br />
                <br />
                <strong>METHODE</strong>
                <br />
                Um die Fragestellungen zur Umverteilung in der OKP zu beantworten, sind Indikatoren zum Einkommen unerlässlich. Zur Definition der
                Einkommensgruppen muss jedem Versicherten ein Einkommen zugeschrieben werden. Für die Annäherung des Einkommens wurde von einem
                bestehenden Einkommensindex ausgegangen (vgl. technischer Appendix). Der Index basiert auf Berufsinformationen, die für einen Teil der
                Versicherten vorhanden sind. Für die Berechnung wurden ausgewählte Berufe als Hoch- (Indikatorwert +1) oder Niedriglohnberufe
                (Indikatorwert −1) klassifiziert. Anschliessend wurde für jede Beobachtung ein Index-Wert ermittelt, indem der durchschnittliche
                Berufsindikator aller im Umkreis von 1 km wohnhaften Individuen berechnet wurde, wobei die nächsten Nachbarn am stärksten gewichtet
                wurden. Der Einkommensindex findet Verwendung in wissenschaftlichen Projekten und hat sich dort zur Annäherung des Einkommens bewährt.
                Für die SID Health wurde die Einkommensannäherung ausgehend von diesem Index weiter verfeinert. So wurden in das Modell folgende
                zusätzliche Variablen aufgenommen: Altersgruppe, Franchise, Bezug von IPV, die Prämienhöhe für die vorhandenen Zusatzversicherungen,
                das Median-Einkommen der Wohngemeinde, die durchschnittliche Wohnfläche der Haushalte in der jeweiligen Gemeinde, der Anteil
                Nicht-EU-Ausländer sowie der Anteil Einfamilienhäuser in der jeweiligen Gemeinde. Mittels logistischer Regression wurde das Modell
                trainiert und validiert. Damit liess sich für jede Person eine Berufskategorie vorhersagen. Basierend auf der Rangordnung des
                Einkommensmodells lassen sich die Individuen pro Jahr und Kanton in vier gleich grosse Einkommensgruppen einteilen. Dabei wurde die
                Stichprobe basierend auf dem Versichertenkollektiv mittels Hochrechnung korrigiert, um Aussagen über die Gesamtpopulation zu
                ermöglichen. Mit Hilfe der Statistiken der Eidgenössischen Steuerverwaltung (ESTV) lassen sich den vier Gruppen jeweils pro Kanton und
                für die ganze Schweiz ein Einkommensintervall zuweisen. Bei den einzelnen Umverteilungsdimensionen wird die jeweilige Gruppe mit dem
                Gesamtschweizer beziehungsweise kantonalen Durchschnitt abgeglichen. So lässt sich bestimmen, ob eine Gruppe über- oder
                unterdurchschnittlich belastet wird und damit auch die entsprechende Umverteilung messen.
                <br />
                <br />
                <strong>ZUSAMMENGESETZTE INDIKATOREN</strong>
                <br />
                <br />
                Die Umverteilung in Frage 1 (totale Umverteilung in der OKP) entspricht der Summe aus den Verteilungswirkungen des
                versicherungstechnischen Ergebnisses (Summe aus den bezahlten Nettoprämien, der Kostenbeteiligungen und der Risikoausgleichszahlungen,
                abzüglich der bezogenen Bruttoleistungen; für die exakte Berechnung, vgl. technischer Appendix), der Prämienverbilligungen (vgl. Frage
                3) sowie der Kantonsanteile an den stationären Spitalleistungen (vgl. Frage 4).
                <br />
                <br />
                Die Daten der Swiss Inequality Database wurden unter Einhaltung gültiger wissenschaftlicher Standards berechnet. Aus
                Datenschutzgründen wurden die Berechnungen von Lukas Huwiler (Datenbäcker GmbH) durchgeführt. Die Berechnungen wurden vom Team
                Sozialpolitik des IWP auf ihre Richtigkeit überprüft. Wir bedanken uns an dieser Stelle bei Prof. Dr. Konstantin Beck und PD Dr.
                Christian Schmid für die inhaltliche Unterstützung.
                <br />
                <br />
                Basierend auf der gleichen Datenquelle wurden bereits folgende wissenschaftliche Arbeiten erstellt und veröffentlicht:
                <ul>
                  <li>
                    Müller, T., Gerfin, M. & Schmid, C. P.R. (2023). Rents for Pills: Financial Incentives and Physician Behavior, Journal of Health
                    Economics, 102711.
                  </li>
                  <li>
                    Schmid, C.P.R., Schreiner, N. & Stutzer, A. (2022). Transfer Payment Systems and Financial Distress: Insights from Health
                    Insurance Premium Subsidies. Journal of the European Economic Association, 20(5): 1829–1858.
                  </li>
                  <li>
                    Bischof, T. & Kaiser, B. (2021). Who cares when you close down? The effects of primary care practice closures on patients, Health
                    Economics, 30(9): 2004-25.
                  </li>
                  <li>
                    Beck, K., Kauer, L., McGuire, T.G. & Schmid, C.P.R. (2020). Improving risk-equalization in Switzerland: Effects of alternative
                    reform proposals on reallocating public subsidies for hospitals, Health Policy, 124(12): 1363-7.
                  </li>
                  <li>
                    Kauer, L., McGuire, T.G. & Beck, K. (2020). Extreme under and overcompensation in morbidity-based health plan payments: The case
                    of Switzerland, Health Policy, 124(1): 61-8.
                  </li>
                  <li>
                    Bischof, T. & Schmid, C.P.R. (2018), Consumer Price Sensitivity and Health Plan Choice in a Regulated Competition Setting, Health
                    Economics, 27(9): 1366-79.
                  </li>
                  <li>Kauer, L. (2017). Long-term Effects of Managed Care, Health Economics, 26(10): 1210-23.</li>
                  <li>
                    Schmid, C.P.R. & Beck, K. (2016). Re-insurance in the Swiss Health Insurance Market: Fit, Power, and Balance, Health Policy,
                    120(7): 848-55.
                  </li>
                  <li>
                    Beck, K., Trottmann, M. & Zweifel, P. (2010). Risk Adjustment in Health Insurance and its Long-Term Effectiveness, Journal of
                    Health Economics, 29(4): 489-98.
                  </li>
                  <li>
                    van Kleef, R.C., Beck, K., van de Ven, W.P.M.M. & van Vliet, R.C.J.A., (2008). Risk equalization and voluntary deductibles: A
                    complex interaction, Journal of Health Economics, 27(2): 427-43.
                  </li>
                </ul>
                <br />
                <br />
                Falls Sie
                <ul>
                  <li>Anregungen oder Fragen zur Swiss Inequality Database Health haben,</li>
                  <li>die den Grafiken zugrundeliegenden Datensätze als Excel-Datei beziehen möchten,</li>
                  <li>oder im Besitze von weiterführenden Datensätzen sind, die wir in die SID Health integrieren können,</li>
                </ul>
                <br />
                dann melden Sie sich bitte unter <a href="mailto:forschung@iwp.swiss">forschung@iwp.swiss</a>.
                <br />
                <br />
                Für die Einzelheiten der Berechnungen finden Sie{' '}
                <a href="https://admin.iwp.swiss/wp-content/uploads/2024/09/SID_Health_Technischer_Appendix.pdf" target="_blank" rel="norefferer">
                  hier
                </a>{' '}
                den technischen Appendix.
              </div>
            </div>
            <div className="dataMethodologyPopup-footer">
              <a href="#" onClick={handleClose} className="dataMethodologyPopup-close d-md-none">
                <div className="dataMethodologyPopup-close-outer">
                  <div className="dataMethodologyPopup-close-inner"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="dataMethodologyPopup-backdrop"></div>
    </>
  );
};

export default DataMethodologyPopupHealth;

import React, { useEffect, useRef, useState } from 'react';

import { graphql } from 'gatsby';
import { gsap } from 'gsap';

// utils
import { pageContextProps } from 'utils/props.utils';

// components
import { handlePageLoaded } from 'components/trans/transLink';
import SEO from 'components/seo/seo';

import classNames from 'classnames';
import imagesLoaded from 'imagesloaded';

// components
import Blocks from 'components/blocks/blocks';

import './sid.template.scss';
import DataMethodologyPopupHealth from 'components/charts/dataMethodologyPopupHealth/dataMethodologyPopupHealth';
import AllQuestionsPopupHealth from 'components/charts/allQuestionsPopupHealth/allQuestionsPopupHealth';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      blocks: any[];
    };
  };
}

const SIDHealthTemplate: React.FC<Props> = (props) => {
  const { title, id, seo } = props.pageContext; // removed hero

  const mainRef = useRef<HTMLDivElement>(null);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);

    if (window.location.hash != '') {
      gsap.to(window, {
        duration: 1,
        scrollTo: window.location.hash,
      });
    }
  }, []);

  return (
    <div className={classNames('page-template', 'sid-page-template', { 'is-loading-complete': isLoaded })} id={id} ref={mainRef}>
      <SEO {...seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{title}</h5>
        </div>
        <div className="section-content">
          <Blocks blocks={props.data.page.blocks} />
        </div>
      </section>
      <DataMethodologyPopupHealth />
      <AllQuestionsPopupHealth />
    </div>
  );
};

export default SIDHealthTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

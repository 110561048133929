import React from 'react';
import { DataCode } from 'utils/charts.utils';
import ChartExport from '../chartExport/chartExport';
import ChartFilter from '../chartFilter/chartFilter';
import ChartResetButton from '../chartResetButton/chartResetButton';
import IWPSlider from '../slider/slider';

const AllQuestionsPopupHealth = () => {
  const handleClose = (e) => {
    window.document.body.classList.remove('allQuestionPopup-open');

    const scrollY = window.document.body.style.top;
    window.document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    var popup = document.querySelector('.allQuestionPopup');
    var backdrop = document.querySelector('.allQuestionPopup-backdrop');
    if (backdrop) {
      backdrop.style.display = 'none';
    }
    if (popup) {
      popup.style.display = 'none';
    }
  };

  const handleResetClick = () => {};

  return (
    <>
      <div className="allQuestionPopup">
        <div className="allQuestionPopup-inner">
          <div className="allQuestionPopup-content">
            <a href="javascript:void(0);" onClick={handleClose} className="allQuestionPopup-close desktop">
              <div className="allQuestionPopup-close-outer">
                <div className="allQuestionPopup-close-inner"></div>
              </div>
            </a>
            <div className="allQuestionPopup-title">So personalisieren Sie die interaktiven Grafiken.</div>
            <div className="allQuestionPopup-text-wrapper">
              <div className="allQuestionPopup-text">
                <div className="align-center">
                  Stellen Sie im Menu über der Grafik Ihre gewünschten Parameter ein:
                  <br />
                  <br />
                </div>
                <div className="grid-row">
                  <div className="grid-cell xs-12 md-4">
                    <div className="allQuestionPopup-label">Einkommensgruppe</div>
                    <div className="chartcombo-selected">
                      Unterste Einkommen
                      <div className="chartcombo-selected-arrow "></div>
                    </div>
                    <div className="allQuestionPopup-explanation">
                      Wählen Sie im Dropdown-Menu, für welche Einkommensgruppe Sie sich interessieren.
                    </div>
                  </div>
                  <div className="grid-cell xs-12 md-4">
                    <div className="allQuestionPopup-label">Ort</div>
                    <div className="chartcombo-selected">
                      Schweiz
                      <div className="chartcombo-selected-arrow "></div>
                    </div>
                    <div className="allQuestionPopup-explanation">Wählen Sie im Dropdown-Menu, für welchen Ort (Kanton) Sie sich interessieren.</div>
                  </div>
                  <div className="grid-cell xs-12 md-4">
                    <div className="allQuestionPopup-label">Jahr / Zeitraum</div>
                    <IWPSlider min={2014} max={2019} value={2017} range={false} onChange={() => {}} />
                    <div className="allQuestionPopup-explanation">
                      Stellen Sie dann den Slider auf das gewünschte Jahr oder den gewünschten Zeitraum.
                    </div>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="grid-cell xs-12 md-8">
                    <div className="grid-row">
                      <div className="grid-cell xs-12 md-4 align-center">
                        <div className="allQuestionPopup-label buttons d-none d-md-block">&nbsp;</div>
                        <ChartResetButton onClick={handleResetClick} />
                        <div className="allQuestionPopup-explanation">
                          Mit dem Zurück-Icon wird die Grafik auf die Grundeinstellung zurückgesetzt.
                        </div>
                      </div>
                      <div className="grid-cell xs-12 md-4 align-center">
                        <div className="allQuestionPopup-label buttons d-none d-md-block">&nbsp;</div>
                        <ChartExport onClick={handleResetClick} />
                        <div className="allQuestionPopup-explanation">Download der Grafik im PNG-Format.</div>
                      </div>
                      <div className="grid-cell xs-12 md-4 align-center">
                        <div className="allQuestionPopup-label buttons d-none d-md-block">&nbsp;</div>
                        <span className="allQuestionPopup-dataMethod">Daten & Methode</span>
                        <div className="allQuestionPopup-explanation">
                          Unter Daten & Methode erfahren Sie mehr darüber, wie sich die Einkommensungleichheit messen lässt.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="allQuestionPopup-footer">
              <a href="#" onClick={handleClose} className="allQuestionPopup-close d-md-none">
                <div className="allQuestionPopup-close-outer">
                  <div className="allQuestionPopup-close-inner"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="allQuestionPopup-backdrop"></div>
    </>
  );
};

export default AllQuestionsPopupHealth;
